import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import logo from '../images/logo.svg'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  const helmetContent = (
    <>
      {`<!--
  <PageMap>
  <DataObject type="document">
    <Attribute name="title">
      The Law Offices of Alexander Z. Lonstein
    </Attribute>
    <Attribute name="description">
      Alexander Z. Lonstein is a General Practice attorney licensed
      to practice law in the states of New York, New Jersey, Alaska,
      and the District of Columbia. Alexander Lonstein is also
      admitted in the Federal District Courts of Eastern District of
      New York, Southern District of New York, and the District of
      New Jersey.{' '}
    </Attribute>
  </DataObject>
  <DataObject type="thumbnail">
    <Attribute name="src" value={logo} />
    <Attribute name="width" value="250" />
    <Attribute name="height" value="247" />
  </DataObject>
</PageMap>
-->`}
    </>
  )

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: 'The Law Offices of Alexander Z. Lonstein',
              },
              {
                name: 'keywords',
                content: 'law,lawyer,attorney,ellenville,ny,new york',
              },
              {
                name: 'og:title',
                content: 'The Law Offices of Alexander Z. Lonstein',
              },
              {
                name: 'og:image',
                content: logo,
              },
              {
                name: 'og:description',
                content: `Alexander Z. Lonstein is a General Practice attorney licensed to practice law in the states of New York, New Jersey, Alaska, and the District of Columbia. Alexander Lonstein is also admitted in the Federal District Courts of Eastern District of New York, Southern District of New York, and the District of New Jersey.`,
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
